import React, { useEffect } from "react";
import "./order.css";

import { useNavigate } from "react-router-dom";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaBookmark, FaPhone } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { CustomButton } from "../../../components";

const Order = ({ item }) => {
  const navigate = useNavigate();
  useEffect(() => {}, []);
  return (
    <div className="delivery" style={{}}>
      <div
        style={{
          position: "absolute",
          right: 5,
          top: 5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          color: item.isDelivered ? "green" : "gray",
          fontSize: 14,
          fontWeight: 700,
        }}
      >
        {item.isDelivered ? "Delivered" : "Pending"}
        <FaBookmark />
      </div>
      <div>
        <b>Order ID: {item.orderID}</b>
      </div>
      <div className="customer">
        {item.isDelivered ? "Delivered" : "Deliver"} to :{" "}
        {`${item.firstName} ${item.lastName}`}
      </div>
      <p>
        Delivery amount : <b>Ksh.{item.delivery_cost ?? 0}</b>
      </p>
      <a
        href={`tel:${item.phoneNumber}`}
        style={{
          textDecoration: "none",
          display: "flex",
          flexDirection: "row",
          gap: 10,
          height: 40,
          alignItems: "center",
          fontWeight: "bold",
          color: "var(--app-color)",
          width: "100%",
        }}
      >
        Call
        <FaPhone />
      </a>

      <div className="customer-adderess">

        <div className="address">
            <label className="one">
            {item.isDelivered ? "Delivered" : "Deliver"} at:
            </label>
          </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {" "}
          <FiMapPin />
          {item.google_data
            ? JSON.parse(item.google_data).results[0].formatted_address
            : "Pinned locaton"}
        </label>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: 10,
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <CustomButton
          title={"Details"}
          backgroundColor={"var(--app-color)"}
          pressAction={() => navigate(`/orders/${item.id}`)}
        />
        {item.latitude ? (
          <CustomButton
            title={"View on map"}
            backgroundColor={"var(--app-color)"}
            pressAction={() => navigate(`/order-map/${item.id}`)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Order;
