import React, { useContext, useEffect, useState } from 'react'
import { FaAd, FaBook, FaBus, FaCar, FaHeart, FaHome, FaMap, FaSearch, FaShoppingBag, FaShoppingCart, FaUser } from 'react-icons/fa'
import "./index.css";
import { FiHeart, FiHome, FiSearch, FiUser } from 'react-icons/fi';
import {AiFillBook, AiFillHome, AiOutlineHome, AiOutlineSearch, AiOutlineShopping, AiOutlineShoppingCart} from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom';
import { CartContext } from '../../contexts/cartContext';

const BottomTabs = () => {
    const [cartData,setCartData] = useContext(CartContext);
    const total = cartData.map(item=>Number(item.quantity)).reduce((prev,curr)=>prev+curr,0);
  const [activeTab,setActiveTab] = useState(null);
  const location = useLocation();
  useEffect(()=>{
    setActiveTab(location.pathname)
  },[location])
  return (
    <div className='bottom-tabs'>
        <Link
        to={"/"}
        className='bottom-tabs-icon'
        >
            <AiOutlineHome className='icon'
            color={activeTab=="/"?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:activeTab=="/"?"flex":"none",
                color:activeTab=="/"?"var(--app-color)":"black"
            }} >Home</label>
        </Link>

        <Link
        to={"/orders"}
        className='bottom-tabs-icon'
        >
            <FaMap className='icon'
            color={activeTab=="/orders"?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:activeTab=="/orders"?"flex":"none",
                color:activeTab=="/orders"?"var(--app-color)":"black"
            }} >Orders</label>
        </Link>

        <Link
        to={"/recents"}
        className='bottom-tabs-icon'
        >
            
            <FaBook className='icon'
            fill={(activeTab=="/recents" || activeTab=="/deliveries")?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:(activeTab=="/recents" || activeTab=="/deliveries")?"flex":"none",
                color:(activeTab=="/recents" || activeTab=="/deliveries")?"var(--app-color)":"black"
            }} >My deliveries</label>
        </Link>

        

        {/* <Link
        to={"/deliveries"}
        className='bottom-tabs-icon'
        >
            
            <FaBus className='icon'
            fill={activeTab=="/deliveries"?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:activeTab=="/deliveries"?"flex":"none",
                color:activeTab=="/deliveries"?"var(--app-color)":"black"
            }} >Deliveries</label>
        </Link> */}
       
        <Link
        to={"/profile"}
        className='bottom-tabs-icon'
        >
            
            <FiUser className='icon'
            color={activeTab=="/profile"?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:activeTab=="/profile"?"flex":"none",
                color:activeTab=="/profile"?"var(--app-color)":"black"
            }} >Profile</label>
        </Link>
    </div>
  )
}

export default BottomTabs