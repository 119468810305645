let Debug = false;
//let BASEURL = "http://169.254.125.30:82";
let BASEURL = "http://127.0.0.1:83";
if(!Debug){
    BASEURL = "https://www.savowarehouse.com";
}

export const APIURL = `${BASEURL}/api/api.php`;
export const APPDATAURLS = {
    adverts:`${APIURL}?adverts=all`,
    categories:`${APIURL}?get-categories=all`,
    products:`${APIURL}?get-products=all`,
    homeProducts:`${APIURL}?get-home-products=all`,
    categoryProducts:`${APIURL}?get-category-products=all&categoryID=`,
    product:`${APIURL}?get-product&productID=`,
    userFavorites:`${APIURL}?customer-likes=all&userID=`,
    deliveryHome:`${APIURL}?delivery-home=all&userID=`,
    availableOrders:`${APIURL}?get-available-orders=all`,
    availableOrder:`${APIURL}?get-available-order`,
    userOrders:`${APIURL}?get-customer-orders=all&userID=`,
    userAddresses:`${APIURL}?get-shipping-addresses=all&userID=`,
    userAddress:`${APIURL}?get-shipping-address=all&addressID=`,
    customerAddresses:`${APIURL}?get-user-addresses=all&userID=`,
    customerAddress:`${APIURL}?get-user-address=all&addressID=`,
    productRatings:`${APIURL}?product-ratings=all&productID=`,
    customerRatings:`${APIURL}?customer-ratings=all&userID=`,
    getAvailableLocations:`${APIURL}?get-available-locations`,
    getDeliveryGuyDeliveries:`${APIURL}?get-delivery-guy-deliveries&deliveryGuyID=`,
    getDeliveryGuyDelivery:`${APIURL}?get-delivery-guy-delivery&deliveryGuyID=`,
    queryURL:`${BASEURL}/api/query.php`,
    getDeliveries:`${APIURL}?get-deliveries=all&deliveryGuyID=`,
    getAvailableOrder:`${APIURL}?get-available-order=all&orderID=`
}

export const MAPS_API_KEY = "AIzaSyCnOXBUe3ltNmU7f3MyX1SCJdVWUp9IRqo";

export const GOOGLEMAPSAPIKEY = "AIzaSyCnOXBUe3ltNmU7f3MyX1SCJdVWUp9IRqo";

export const MAPBOXACCESSTOKEN = "pk.eyJ1IjoiZWRnYXJuZ2VsZXNvIiwiYSI6ImNtNGR4NmFmeTBveDQyanNjN3psYWlvdnQifQ.SIm39odew131L5OjncS1DQ";

export {BASEURL};